import type { Category } from '@prezly/sdk';
import { translations } from '@prezly/theme-kit-intl';
import { useCompanyInformation, useNewsroom } from '@prezly/theme-kit-nextjs';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { CategoriesTabs, SectionHeading, StoryCard } from '@/components';
import { useSonyVisibleCategories } from '@/hooks';
import type { StoryWithImage } from 'types';

import Illustration from '@/public/images/no-stories-illustration.svg';

import styles from './StoriesList.module.scss';

type Props = {
    categoriesTabsDisabled: boolean;
    description?: ReactNode;
    onChangeCategory: (category: Category | undefined) => void;
    stories: StoryWithImage[];
    title?: ReactNode;
    withCategoryTabs?: boolean;
};

function StoriesList({
    categoriesTabsDisabled,
    description,
    onChangeCategory,
    stories,
    title,
    withCategoryTabs,
}: Props) {
    const { name } = useCompanyInformation();
    const { display_name } = useNewsroom();
    const categories = useSonyVisibleCategories();

    if (!stories.length) {
        return (
            <div className={styles.noStories}>
                <Illustration />
                <h1 className={styles.noStoriesTitle}>
                    <FormattedMessage
                        {...translations.noStories.title}
                        values={{ newsroom: name || display_name }}
                    />
                </h1>
                <p className={styles.noStoriesSubtitle}>
                    <FormattedMessage {...translations.noStories.subtitle} />
                </p>
            </div>
        );
    }

    return (
        <>
            {title && <SectionHeading headingTag="h2" title={title} description={description} />}

            {categories.length > 0 && withCategoryTabs && (
                <CategoriesTabs
                    disabled={categoriesTabsDisabled}
                    onChange={onChangeCategory}
                    categories={categories}
                />
            )}

            {stories.length > 0 && (
                <div className={styles.storiesContainer}>
                    {stories.map((story) => (
                        <StoryCard key={story.uuid} story={story} />
                    ))}
                </div>
            )}
        </>
    );
}

export default StoriesList;
