import { type PaginationProps } from '@prezly/theme-kit-nextjs';
import { FormattedMessage } from 'react-intl';

import { HighlightedStoryCard, NewsTopics } from '@/components';
import { CUSTOM_MESSAGES } from '@/intl';
import type { StoryWithImage } from 'types';

import Layout from '../Layout';
import PaginatedStories from '../PaginatedStories';

import styles from './Home.module.scss';

interface Props {
    stories: StoryWithImage[];
    pagination: PaginationProps;
    highlightedStory: StoryWithImage | undefined;
}

export default function Home({ stories, pagination, highlightedStory }: Props) {
    return (
        <Layout>
            {highlightedStory && (
                <div className={styles.highlightedStoryContainer}>
                    <HighlightedStoryCard story={highlightedStory} />
                </div>
            )}

            <PaginatedStories
                initialStories={stories}
                pagination={pagination}
                title={<FormattedMessage {...CUSTOM_MESSAGES.headings.homeStories} />}
                variant="with-link"
                highlightedStory={highlightedStory}
            />

            <div className={styles.newsTopics}>
                <div className="container">
                    <NewsTopics />
                </div>
            </div>
        </Layout>
    );
}
