import type { Category } from '@prezly/sdk';
import { translations } from '@prezly/theme-kit-intl';
import type { PaginationProps } from '@prezly/theme-kit-nextjs';
import { useRouter } from 'next/router';
import { type ReactNode, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { Pagination } from '@/components';
import type { StoriesQuery } from '@/hooks';
import { usePaginatedStories } from '@/hooks';
import { ButtonLink } from '@/ui';
import { IconArrowRight } from 'icons';
import type { StoryWithImage } from 'types';

import StoriesList from './StoriesList';

import styles from './PaginatedStories.module.scss';

type Props = {
    initialStories: StoryWithImage[];
    pagination: PaginationProps;
    category?: Category;
    variant?: 'with-link' | 'with-pagination';
    highlightedStory?: StoryWithImage;
    title?: ReactNode;
    description?: ReactNode;
    withCategoryTabs?: boolean;
};

function PaginatedStories({
    initialStories,
    pagination,
    category,
    variant = 'with-pagination',
    highlightedStory,
    title,
    description,
    withCategoryTabs = true,
}: Props) {
    const router = useRouter();

    const [initialQuery] = useState<StoriesQuery>({
        page: pagination.currentPage,
        pageSize: pagination.pageSize,
        category,
        include: ['thumbnail_image'],
        withHighlightedStory: Boolean(highlightedStory),
        pinning: true,
    });

    const [query, setQuery] = useState<StoriesQuery>(initialQuery);

    const { stories, storiesTotal, isLoading } = usePaginatedStories({
        initialQuery,
        query,
        initialData: { stories: initialStories, storiesTotal: pagination.itemsTotal },
        highlightedStory,
    });

    function handleChangeCategory(selectedCategory: Category | undefined) {
        setQuery((q) => ({ ...q, page: 1, category: selectedCategory }));
        router.push({ query: undefined }, undefined, { shallow: true });
    }

    function handlePageChange(newPage: number) {
        setQuery({ ...query, page: newPage });
        router.push({ query: { ...router.query, page: newPage } }, undefined, { shallow: true });
    }

    return (
        <div>
            <StoriesList
                withCategoryTabs={withCategoryTabs}
                categoriesTabsDisabled={isLoading}
                onChangeCategory={handleChangeCategory}
                title={title}
                description={description}
                stories={stories}
            />

            <div className={styles.wrapper}>
                {variant === 'with-link' && (
                    <ButtonLink
                        icon={IconArrowRight}
                        iconPlacement="right"
                        variation="primary"
                        className={styles.button}
                        href="/news"
                    >
                        <FormattedMessage {...translations.actions.more} />
                    </ButtonLink>
                )}

                {variant === 'with-pagination' && (
                    <Pagination
                        key={query.category?.id ?? 'All'}
                        count={storiesTotal / pagination.pageSize}
                        defaultPage={pagination.currentPage}
                        onChange={handlePageChange}
                    />
                )}
            </div>
        </div>
    );
}

export default PaginatedStories;
